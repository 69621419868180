import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment"
import { Layout, Seo, Container, PostCard, Author, AdsCarousel, RoundedBox, DropdownShare, Theme } from "../../components"
import { Search, Share, ArrowRight } from '../../assets/vectors'
import * as styles from './style.module.scss'


const CategoryTemplate = ({ data, pageContext }) => {
  const { page, category, categories, posts, playBanner } = data

  const [searchTerm, setSearchTerm] = React.useState("")
  const [searchResults, setSearchResults] = React.useState([])
  const [searchIsActive, setSearchIsActive] = React.useState(false)

	const URL_PREFIX = pageContext.lang === "en_us" ? "" : "pt"
	const READ_MORE = pageContext.lang === "en_us" ? "read more" : "leia mais"

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = `/${URL_PREFIX}/blog/${category.slug}/${currentPage - 1 === 1 ? "" : "page=" + (currentPage - 1).toString()}`
  const nextPage = `/${URL_PREFIX}/blog/${category.slug}/page=${(currentPage + 1).toString()}`

  const handleSearchChange = event => {
    setSearchTerm(event.currentTarget.value)
  }

  const hero = page.blogPageFields.sectionHero.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

  const playFields = playBanner.homePageFields?.sectionPlay.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

  const filteredPosts = posts.edges.filter(post =>
    post.node.categories.nodes.map(node => node.databaseId).includes(10)
  )

  const normalizedPosts = filteredPosts.map(post => ({
    categories: post.node.categories.nodes.filter(
      cat => ![9, 10].includes(cat.databaseId)
    ),
    author: post.node.blogFields.wannaAuthor?.postAuthorFields,
    blog: post.node.blogFields.blogFields?.find(
      variant =>
        variant.languageValue && variant.languageValue.slug === pageContext.lang
    ),
    featured: post.node.blogFields.isFeatured,
    date: moment(post.date).format("ll"),
    slug: post.node.slug,
    id: post.node.databaseId,
  }))




  const nonFeaturedPosts = normalizedPosts










  React.useEffect(() => {
    const results = normalizedPosts.filter(
      post =>
        post.blog?.blogTitle.toLowerCase().includes(searchTerm.toLowerCase()) |
        post.blog?.excerpt.toLowerCase().includes(searchTerm.toLowerCase()) |
        post.categories
          .map(cat => cat.name.toLowerCase())[0]
          .includes(searchTerm.toLowerCase())
    )

    setSearchResults(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  React.useEffect(() => {
    if (pageContext.lang === "en_us") navigate("/")
  }, [pageContext.lang])

  return (
    <Layout>
      <Seo title={hero.title} />

			<section className={styles.blog}>
				<RoundedBox
					className={styles.roundedBox}
					transform="rotate(26deg)"
					width="916px"
					height="996px"
					position={{
						top: "-180px",
						left: "-230px"
					}}
				/>

				<Container>
          {searchIsActive === false ? (
            <>


              <div className={`${styles.blog__head} flex align-center`}>
    						<h1 className={styles.title}>{category.name}</h1>
    						{categories?.nodes && categories?.nodes.length > 0 &&
    							<ul className={styles.categories}>
    								{
    									categories.nodes.map(category =>
    										<li key={category.databaseId}><Link className="pill" to={`/${URL_PREFIX}/blog/${category.slug}/`}>{category.name}</Link></li>
    									)
    								}
    							</ul>}
    						<a className={styles.search} onClick={() => (setSearchIsActive(!searchIsActive),
                    setSearchTerm(""))}>
    							<Search />
    						</a>
    					</div>



    					<div className={styles.blog__body}>
    						<div className={styles.posts}>
    							{nonFeaturedPosts.slice(0, 3).map(post =>
    									post && post.blog && <>
    									<PostCard
    										className={styles.post}
    										post={post}
    										key={post.id}
    										pageContext={pageContext}
    										shouldHave={{
    											author: true,
    											links: true,
    											excerpt: true
    										}}
    									/>
    									<hr />
    								</>)}
    						</div>
    						<AdsCarousel data={playBanner} />
        				<div className={styles.posts}>
        					{nonFeaturedPosts.splice(3).map(post =>
                  	post && post.blog && <>
                    <PostCard
        							className={styles.post}
        							post={post}
        							key={post.id}
        							pageContext={pageContext}
        							shouldHave={{
        								author: true,
        								links: true,
        								excerpt: true
        							}}
        						/>
        						<hr />
                	</>)}
        				</div>
                {!isFirst && (
                  <Link to={prevPage} rel="prev">
                    ← Previous
                  </Link>)}
                  <span>Page {currentPage}</span>
                {!isLast && (
                  <Link to={nextPage} rel="next">
                    Next →
                  </Link>)}
    			   </div>
            </>
          ) : null}



          {searchIsActive === true ? (
            <Theme.Search>
              {searchTerm !== "" ? (
                <h5>
                  {pageContext.lang === "pt_br" ? "Digite sua busca" : "Type your search"}
                </h5>
              ): null}
              <div className="search-bar">
                <input
                  type="text"
                  placeholder={
                    pageContext.lang === "pt_br" ? "Digite sua busca" : "Type your search"
                  }
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="closer"
                  onClick={() => setSearchIsActive(!searchIsActive)}>
                </div>
              </div>
            </Theme.Search>
          ) : null}
          {searchIsActive === true && searchTerm !== "" ? (
            <div className={styles.blog__body}>
              {searchResults.length > 0 ?
                <div className={styles.posts}>
      					  {searchResults.slice(0, 3).map(post =>
      					    post && post.blog && <>
    									<PostCard
    										className={styles.post}
    										post={post}
    										key={post.id}
    										pageContext={pageContext}
    										shouldHave={{
    											author: true,
    											links: true,
    											excerpt: true
    										}}
    									/>
    									<hr />
    								</>
                  )}
                </div>
              :
              pageContext.lang === "pt_br"
                ? "0 Resultados..."
                : "0 Results..."}
              <AdsCarousel data={playBanner} />
              {searchResults.length > 0 ?
                <div className={styles.posts}>
          		    {searchResults.splice(3).map(post =>
                    post && post.blog && <>
                      <PostCard
          						  className={styles.post}
          							post={post}
          							key={post.id}
          							pageContext={pageContext}
          							shouldHave={{
          								author: true,
          								links: true,
          								excerpt: true
          							}}
          						/>
          						<hr />
                  	</>
                  )}
          			</div>
              : null}
            </div>
          ) : null}
				</Container>
			</section>
    </Layout>
  )
}

export const Query = graphql`
  query CategoryTemplate($id: Int, $limit: Int!, $skip: Int!) {
    page: wpPage(slug: { eq: "blog" }) {
      blogPageFields {
        sectionHero {
          languageValue {
            slug
            name
          }
          bannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    category: wpCategory(databaseId: { eq: $id }) {
      slug
      name
      databaseId
      count
      parentDatabaseId
    }
    categories: allWpCategory(
      filter: {
        count: { gt: 0 }
        databaseId: { nin: [9, 10] }
        parentDatabaseId: { nin: [21] }
      }
    ) {
      nodes {
        slug
        name
        databaseId
        wpChildren {
          nodes {
            databaseId
            name
            count
            slug
          }
        }
      }
    }
    posts: allWpPost(
      limit: $limit,
      skip: $skip,
      filter: {
        status: {eq: "publish"},
        categories: {
          nodes: {
            elemMatch: {
              databaseId: {eq: $id}
            }
          }
        },
        blogFields: {
          blogFields: {
            elemMatch: {
              blogTitle: {ne: null},
              blogImage: {
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {ne: "null"}
                  }
                }
              },
              excerpt: {ne: null},
              languageValue: {
                slug: {ne: null}
              },
              readingTime: {ne: null}
            }
          }
        }
      }
    ) {
      edges {
        node {
          categories {
            nodes {
              databaseId
              slug
              name
              parentDatabaseId
            }
          }
          date
          slug
          databaseId
          blogFields {
            isFeatured
            wannaAuthor {
              ... on WpAuthor {
                postAuthorFields {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 80, layout: FIXED)
                      }
                    }
                  }
                  link
                  name
                }
              }
            }
            blogFields {
              blogTitle
              blogImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000, layout: FIXED)
                  }
                }
              }
              excerpt
              languageValue {
                slug
              }
              readingTime
            }
          }
        }
      }
    }
    playBanner: wpPage(slug: { eq: "home" }) {
      databaseId
      homePageFields {
        sectionPlay {
          languageValue {
            slug
            name
          }
          banners {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            linkUrl
          }
        }
      }
    }
  }
`

export default CategoryTemplate
