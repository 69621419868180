// extracted by mini-css-extract-plugin
export var blog = "style-module--blog--1oIP1";
export var blog__head = "style-module--blog__head--3gozu";
export var title = "style-module--title--2Fea9";
export var categories = "style-module--categories--322C_";
export var search = "style-module--search--2b6ym";
export var blog__body = "style-module--blog__body--2S0Sg";
export var featured = "style-module--featured--29dj3";
export var featured__image = "style-module--featured__image--cn6hG";
export var image = "style-module--image--xsTEA";
export var featured__content = "style-module--featured__content--3XlKF";
export var author = "style-module--author--2cJ5W";
export var excerpt = "style-module--excerpt--2Fo2N";
export var links = "style-module--links--nKHJX";
export var share = "style-module--share--191nR";
export var posts = "style-module--posts--2aJUE";
export var post = "style-module--post--1QTO2";
export var roundedBox = "style-module--roundedBox--2vr-V";